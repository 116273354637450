<template>
    <laboratories-module-component></laboratories-module-component>
</template>

<script>
    import LaboratoriesModuleComponent from "@/components/admin/modules/LaboratoriesModuleComponent";

    export default {
        name: "LaboratoriesModule",
        title: "Gestión de Laboratorios | Turismo BC",
        components: {LaboratoriesModuleComponent}
    }
</script>

<style scoped>

</style>